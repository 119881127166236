import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';


import './navigation.css';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <ul className="navigationul">
    <li className='muzecartlogo'>
      <div className='muzecartimg'>
        <Link to={ROUTES.LANDING}><img  alt="muzecart icon logo" class="circlelogo" src="https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fomuzelogo.png?alt=media&amp;token=9fa12178-aac1-4ec8-a8c4-ddeb41b35203">
        </img></Link>
      </div>
      <div className='muzecarttext'>
        <Link to={ROUTES.LANDING}><img alt="muzecart logo text" class="muzelogo" src="https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmezucartlogo.png?alt=media&amp;token=c347d8b0-a7a3-4158-adf9-ee08c0fd930b">
        </img></Link>
        <h5>Get the Best of Both Worlds</h5>
      </div>
    </li>
    <li className='mainnav'>
      <NavLink  activeClassName='active' to={ROUTES.HOME} >Dashboard</NavLink>
    </li>
    <li className='mainnav'>
      <NavLink activeClassName='active' to={ROUTES.ACCOUNT}>Settings</NavLink>
    </li>
    {!!authUser.roles[ROLES.ADMIN] && (
      <li className='mainnav'>
        <NavLink activeClassName='active' to={ROUTES.ADMIN}>Admin</NavLink>
      </li>
    )}
    <li className='mainnav'>
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => ('');

export default Navigation;
