import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div className='maindiv'>
    <div className='leftsite'>
      <img alt="muzecart icon logo" className='circlelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fomuzelogo.png?alt=media&token=9fa12178-aac1-4ec8-a8c4-ddeb41b35203'>
      </img>
        <br />
      <img alt="muzecart logo text" className='muzelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmezucartlogo.png?alt=media&token=c347d8b0-a7a3-4158-adf9-ee08c0fd930b'>
      </img>
        <br />
        <h5>Get the Best of Both Worlds</h5>
        <h1>Welcome back!</h1>
        <h2>Sign into your account:</h2>

        <SignInForm />
        <PasswordForgetLink />
    </div>
    <div className='rightside'>
      <img alt='wordpress and shopify integration cart' className='maincomp' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmuzecartlogo3.png?alt=media&token=7c95b8fe-51e2-4a6c-b3fc-775dfcd74f7f'>
      </img>
        <div className='starss'>
          <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
          </img>
          <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
          </img>
          <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
          </img>
          <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
          </img>
          <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
          </img>
        </div>
        <h3>"Smart & easy way to sell & ship products."</h3>
        <div className='reviewauthor'>
          <h4>- Kerry Mellin, Founder of</h4>
            <img alt='eazyhold logo review' className='review' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Feazyholdlogo2.png?alt=media&token=8abe2b76-89a8-49cc-9eb4-1c36d74ec0d8'>
            </img>
        </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  username: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
   const { email, username, password } = this.state;

   if((username) && (email === '')){
     this.props.firebase.users().where('username', '==', username).get()
     .then(snapshot => {
       if (snapshot.empty) {
         this.setState({ error: {message: 'Username or password is incorrect'} });
      }else{
        snapshot.forEach(doc => {
          this.props.firebase
            .doSignInWithEmailAndPassword(doc.data().email, password)
            .then(() => {
              this.setState({ ...INITIAL_STATE });
              this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
              this.setState({ error });
            });

        });
      }
         })
     .catch(error => {
       this.setState({ error });
     });
   }

if(email){
  this.props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
      this.setState({ ...INITIAL_STATE });
      this.props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      this.setState({ error });
    });
}

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, username, password, error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
      <div className = 'halffield'>
      <label>Email or Username:</label>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="hidden"
          placeholder="Email Address"
        />
        <input
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder="Username"
            required
        />
        </div>
        <div className = 'halffield'>
        <label>Password:</label>
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
          required
        />
        </div>
        {error && <p className='error'>{error.message}</p>}
        <div className='formbuttons'>
        <button type="submit">
          Sign In
        </button>
        <Link to={ROUTES.LANDING}>Sign Up</Link>
        </div>
      </form>
    );
  }
}


const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm};
