import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div className='maindiv'>
    <div className='leftsite'>
      <img alt="muzecart icon logo" className='circlelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fomuzelogo.png?alt=media&token=9fa12178-aac1-4ec8-a8c4-ddeb41b35203'>
      </img>
        <br />
      <img alt="muzecart logo text" className='muzelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmezucartlogo.png?alt=media&token=c347d8b0-a7a3-4158-adf9-ee08c0fd930b'>
      </img>
        <br />
        <h5>Get the Best of Both Worlds</h5>
        <h1>Reset Password</h1>
        <h2>We'll email you a password reset link:</h2>
        <PasswordForgetForm />
        </div>
        <div className='rightside'>
          <img alt='wordpress and shopify integration cart' className='maincomp' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmuzecartlogo3.png?alt=media&token=7c95b8fe-51e2-4a6c-b3fc-775dfcd74f7f'>
          </img>
            <div className='starss'>
              <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
              </img>
              <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
              </img>
              <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
              </img>
              <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
              </img>
              <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
              </img>
            </div>
            <h3>"Smart & easy way to sell & ship products."</h3>
            <div className='reviewauthor'>
              <h4>- Kerry Mellin, Founder of</h4>
                <img alt='eazyhold logo review' className='review' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Feazyholdlogo2.png?alt=media&token=8abe2b76-89a8-49cc-9eb4-1c36d74ec0d8'>
                </img>
            </div>
        </div>
      </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};
const SENT_STATE = {
  email: '',
  error: null,
  success: {message: 'Sent! Please check your email for the reset link.'},
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...SENT_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, success } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
      {success && <p className='success'>{success.message}</p>}
      <div className = 'halffield'>
      <label>Email or Username:</label>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          required
        />
        </div>
        {error && <p className='error'>{error.message}</p>}
        <div className='formbuttons'>
        <button type="submit">
          Reset My Password
        </button>
        </div>
        <p><Link to={ROUTES.SIGN_IN}>Back to Login</Link></p>
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
