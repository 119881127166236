import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      plans: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .plans()
      .onSnapshot(snapshot => {
        let plans = [];

        snapshot.forEach(doc =>
          plans.push({ ...doc.data(), uid: doc.id }),
        );

        this.setState({
          plans,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { plans, loading } = this.state;

    return (
      <div>
        <h2>Plans</h2>
        {loading && <div>Loading ...</div>}
        <ul>
          {plans.map(plan => (
            <li key={plan.uid}>
              <span>
                <strong>ID:</strong> {plan.uid}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withFirebase(Plans);
