import React from 'react';
import { Redirect } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import { SignUpForm } from '../SignUp';
import './home.css';


const Landing = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <GoHome authUser={authUser} />
      ) : (
        <Unloggedin />
      )
    }
  </AuthUserContext.Consumer>
);

const GoHome = () => (

  <Redirect to='/home' />

);

const Unloggedin = () => (

<div className='maindiv'>
  <div className='leftsite'>
    <img alt="muzecart icon logo" className='circlelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fomuzelogo.png?alt=media&token=9fa12178-aac1-4ec8-a8c4-ddeb41b35203'>
    </img>
      <br />
    <img alt="muzecart logo text" className='muzelogo' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmezucartlogo.png?alt=media&token=c347d8b0-a7a3-4158-adf9-ee08c0fd930b'>
    </img>
      <br />
      <h5>Get the Best of Both Worlds</h5>
      <h1>The best eCommerce experience for your website!</h1>
      <h2>Combine the SEO power of Wordpress and shop management of Shopify.</h2>
      <SignUpForm />
  </div>
  <div className='rightside'>
    <img alt='wordpress and shopify integration cart' className='maincomp' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fmuzecartlogo3.png?alt=media&token=7c95b8fe-51e2-4a6c-b3fc-775dfcd74f7f'>
    </img>
      <div className='starss'>
        <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
        </img>
        <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
        </img>
        <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
        </img>
        <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
        </img>
        <img alt="5 star rating" className='star' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Fstargreen.png?alt=media&token=7bc7c177-6b07-4a15-9dbe-e326ac26f83e'>
        </img>
      </div>
      <h3>"Smart & easy way to sell & ship products."</h3>
      <div className='reviewauthor'>
        <h4>- Kerry Mellin, Founder of</h4>
          <img alt='eazyhold logo review' className='review' src='https://firebasestorage.googleapis.com/v0/b/muzela-21274.appspot.com/o/assets%2Feazyholdlogo2.png?alt=media&token=8abe2b76-89a8-49cc-9eb4-1c36d74ec0d8'>
          </img>
      </div>
  </div>
</div>
);

export default Landing;
