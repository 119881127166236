import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import './signup.css';
const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  name: '',
  terms: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  Account with this E-Mail address already exists.
`;

const ERROR_CODE_USER_EXISTS = 'auth/username-already-in-use';

const ERROR_MSG_USER_EXISTS = `
  Account with this Username already exists.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, password, name } = this.state;
    const roles = {};
    console.log('signup start checks');

      this.props.firebase.users().where('username', '==', username).get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('username not taken');
            this.props.firebase
              .doCreateUserWithEmailAndPassword(email, password)
              .then(authUser => {
                // Create a user in your Firebase realtime database
                return this.props.firebase.user(authUser.user.uid).set(
                  {
                    username,
                    email,
                    roles,
                    name,
                  },
                  { merge: true },
                );
              })
              .then(() => {
                return this.props.firebase.doSendEmailVerification();
              })
              .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
              })
              .catch(error => {
                if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                  error.message = ERROR_MSG_ACCOUNT_EXISTS;
                }

                this.setState({ error });
              });
       }else{
       this.setState({ error: {
         code: ERROR_CODE_USER_EXISTS,
         message: ERROR_MSG_USER_EXISTS} });
       }
          })
      .catch(error => {
        this.setState({ error });
      });



    event.preventDefault();
  };

  onChange = event => {
    if(event.target.name === 'username'){
      let str = event.target.value;
      let newvalue = str.replace(/[^A-Z0-9]+/ig, "-");
      this.setState({ username : newvalue });
    }else{
      this.setState({ [event.target.name]: event.target.value });
    };
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      password,
      name,
      terms,
      error,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
      <div className = 'halffield'>
      <label>Your Email:</label>
      <input
        name="email"
        value={email}
        onChange={this.onChange}
        type="text"
        required
      />
      </div>
      <div className = 'halffield'>
      <label>Your Full Name or Company:</label>
      <input
        name="name"
        value={name}
        onChange={this.onChange}
        type="text"
        required
      />
      </div>
      <div className = 'halffield'>
      <label>Choose Login Username:</label>
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          required
        />
        </div>
        <div className = 'halffield'>
        <label>Set Password:</label>
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          required
        />
        </div>
        <div className = 'fullfield'>
        <label>
          <input
            name="terms"
            type="checkbox"
            checked={terms}
            onChange={this.onChangeCheckbox}
            required
          />
          I agree to <Link to={ROUTES.SIGN_UP}>Terms and Conditions</Link>
        </label>
        </div>
        {error && <p className='error'>{error.message}</p>}
        <div className='formbuttons'>
        <button type="submit">
          Sign-up
        </button>
        <Link to={ROUTES.SIGN_IN}>Login</Link>
        </div>
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.LANDING}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
