import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import {
  AuthUserContext,
  withAuthorization
} from '../Session';
import Messages from '../Messages';
import { Plans, UserPlan } from '../Plan';
import Shopify from '../Shopify';
import Wordpress from '../Wordpress';
import * as ROUTES from '../../constants/routes';

import './dashboard.css';

const HomePage = () => (
    <AuthUserContext.Consumer>
      {authUser => (
  <div className='maindashboard'>
  <div className='messages'>
        <Messages />
  </div>
    <div className='thirdside'>
      <h2>Shopify:</h2>
      <p>Management destination for all your products, orders and payments:</p>

        <Route exact path={ROUTES.HOME} component={Shopify} />
    </div>
    <div className='thirdside'>
      <h2>Wordpress(es):</h2>
      <p>Live website(s) content and where to display products to customers:</p>

      <Route exact path={ROUTES.HOME} component={Wordpress} />
    </div>
    <div className='thirdside'>
      <h2>My Plan:</h2>
      <p>MuzeCart payment plan - choose the option that works best for your shop:</p>


      <Route exact path={ROUTES.HOME} component={UserPlan} />
    </div>
  </div>
)}
</AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(HomePage);
